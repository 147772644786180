<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="header">
      <div class="container">

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Debto</h5>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>
                    {{ authError }}
                  </b-alert>
                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="username" class="form-label">Имя пользователя</label>
                      <input type="username" class="form-control" id="username" v-model="username"
                        :class="{ 'is-invalid': submitted && v$.username.$error }" />
                      <div v-for="(item, index) in v$.username.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="password-input">Пароль</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <div class="form-icon right">

                          <input v-if="showPassword" type="text" v-model="password" class="form-control pe-5"
                            :class="{ 'is-invalid': submitted && v$.password.$error }" id="password-input" />
                          <input v-else type="password" v-model="password" class="form-control pe-5"
                            :class="{ 'is-invalid': submitted && v$.password.$error }" id="password-input2" />

                          <i :class="[!showPassword ? 'ri-eye-fill' : 'ri-eye-off-fill']" class="fs-5 text"
                            @click="showPassword = !showPassword"></i>
                        </div>
                        <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                          <span v-if="v$.password.required.$message">
                            {{ v$.password.required.$message }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100" type="submit">Войти</button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Debto </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>

<script>
import { mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "../../../app.config";

import { authMethods, notificationMethods } from "@/state/helpers";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  page: {
    title: "Логин",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      username: null,
      password: null,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      showPassword: false
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Имя пользователя обязательно", required),
    },
    password: {
      required: helpers.withMessage("Пароль обязателен", required),
    },
  },
  computed: {
    ...mapState("auth", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const { username, password } = this;
        if (username && password) {
          this.logIn({
            username,
            password,
          });
        }
      }
    },
  },
};
</script>

<style>
.header {
  margin-top: 180px;
}
.auth-one-bg {
  background: none;
}
.card {
  z-index: 99;
}
</style>
